<template>
    <div class="help-container">
        <div class="top-box">
            <div @click="showVideo" v-throttle>用户操作视频</div>
            <div style="margin: 0 10px">|</div>
            <div @click="showHelp" v-throttle>用户使用手册</div>
        </div>
        <div class="video-box">
            <video class="video-box" controls>
                <!-- <source :src='file'> -->
            </video>
            <img class="close-btn" src="/img/help/help-close.png" alt="" @click="back">
        </div>
    </div>
</template>
<script>

export default {
    name: "Help",
    data() {
        return {

        };
    },
    computed: {
    },
    components: {
    },
    mounted() {
    },
    methods: {
        showVideo() {
            console.log(1)
        },
        showHelp() {
            console.log(2)
        },
        back() {
            this.$router.go (-1) 
        }
    },
};
</script>
<style lang="scss">
.help-container {
    height: 100%;
    width: 100%;
    background-image: url('/img/help/background-help.png');
    background-size: 100% 100%;
    .top-box {
        position: absolute;
        top: 50px;
        left: 50%;
        transform: translateX(-50%);
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 14px;
        font-weight: 400;
        color: #FFFFFF;
        line-height: 21px;
    }
    .video-box {
        width: 100%;
        height: 244px;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%,-50%);
        video {
            width: 100%;
            height: 244px;
        }
        .close-btn {
            position: absolute;
            right: 9px;
            top:-28px;
            width: 19px;
            height: 19px;
        }
    }
}
</style>